import React, { useState } from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import { isOriginInUse } from '../../util/search.js';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext.js';
import { useRouteConfiguration } from '../../context/routeConfigurationContext.js';
import SearchSportsForm from '../../components/SearchSportsForm/SearchSportsForm.js';
import { createResourceLocatorString } from '../../util/routes.js';
import { makeFormSaveValues, makeLocalSaveValues } from '../../util/data';
import { SCHOOL_TYPE, SEARCH_TYPE_ADDRESS, SEARCH_TYPE_SCHOOL } from '../../util/types.js';

import css from './PageBuilder.module.css';


const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    id,
    landingPage,
    ...pageProps
  } = props;

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const localStorageValues = (typeof window != "undefined" && window.localStorage.getItem("search") && JSON.parse(window.localStorage.getItem("search"))) || {};
  const { pub_sport, address, bounds, type = SEARCH_TYPE_ADDRESS, schoolName } = localStorageValues || {};

  const [searchType, setSearchType] = useState([SEARCH_TYPE_ADDRESS, SEARCH_TYPE_SCHOOL].includes(type) ? type : SEARCH_TYPE_ADDRESS);

  let initialValues = {};
  if (pub_sport && address && bounds && bounds.ne && bounds.sw && searchType == SEARCH_TYPE_ADDRESS) {
    initialValues = makeFormSaveValues(localStorageValues, type);
  }

  if (searchType == SEARCH_TYPE_SCHOOL && schoolName) {
    initialValues["schoolName"] = schoolName;
  }


  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const handleSubmit = (values) => {
    // if (values && values.sport && values.location && values.location.selectedPlace && values.location.selectedPlace.bounds) {
    //   const topbarSearchParams = () => {
    //     // topbar search defaults to 'location' search
    //     const { search, selectedPlace } = values?.location;
    //     const { origin, bounds } = selectedPlace;
    //     const originMaybe = isOriginInUse(config) ? { origin } : {};

    //     return {
    //       ...originMaybe,
    //       address: search,
    //       bounds,
    //     };
    //   };
    //   const searchParams = {
    //     pub_sport: values.sport,
    //     pub_learningType: SCHOOL_TYPE
    //   };

    //   if (values?.location) {
    //     Object.assign(searchParams, { ...topbarSearchParams() })
    //   }

    //   typeof window != "undefined" && window.localStorage.setItem("search", JSON.stringify(makeLocalSaveValues(values)));
    //   return history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { ...searchParams }));
    // }

    // else 
    if (values && values.schoolName) {
      const searchParams = { schoolName: values.schoolName };
      typeof window != "undefined" && window.localStorage.setItem("search", JSON.stringify({ ...searchParams, type: SEARCH_TYPE_SCHOOL }));
      return history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { keywords: values.schoolName, pub_learningType: SCHOOL_TYPE }));
    }

  }

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage="LandingPage" />
              </Topbar>
              {landingPage ? <Main as="main" className={css.main}>
                <div className={id === "hero" ? css.heroSlider : css.sectionContent}>
                  <div className={css.homeSearchBar}>
                    <SearchSportsForm
                      onSubmit={handleSubmit}
                      initialValues={{ ...initialValues }}
                      appConfig={config}
                      isMobile={false}
                      searchType={searchType}
                      setSearchType={setSearchType}
                    />
                  </div>
                </div>
              </Main> 
              : <Main as="main" className={css.mainPageBuilder}>
                  {sections.length === 0 && inProgress ? (
                    <LoadingSpinner />
                  ) : (
                    <SectionBuilder sections={sections} options={options} />
                  )
                  }
                </Main>
              }
              <FooterContainer />
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;
