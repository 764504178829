import React, { Component } from 'react';
import Select, { components } from 'react-select'
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, FormattedMessage, intl } from '../../util/reactIntl';
import Button from '../Button/Button';
import FieldSelect from '../FieldSelect/FieldSelect';
import { FieldLocationAutocompleteInput, FieldTextInput, Form, LocationAutocompleteInput } from '../../components';

import { allSports } from '../../config/configGlobal';
import SportsIcon from "./SportsIcon/SportsIcon";
import { SEARCH_TYPE_ADDRESS, SEARCH_TYPE_SCHOOL } from '../../util/types';

import css from './SearchSportsForm.module.css';

const identity = v => v;

const { Option, SingleValue } = components;
const IconOption = props => (
    <Option {...props}>
        <SportsIcon type={props.data.key} />
        <span className={css.selectedLabel}>{props.data.label}</span>
    </Option>
);
const IconSingleValue = (props) => (
    <SingleValue {...props}>
        <SportsIcon type={props.data.key} />
        <span className={css.selectedLabel}>{props.data.label}</span>
    </SingleValue>
);

class SearchSportsFormComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { type, searchType, setSearchType } = this.props;
        return (
            <FinalForm
                {...this.props}
                render={formRenderProps => {
                    const {
                        rootClassName,
                        className,
                        intl,
                        isMobile,
                        form,
                        autoFocus,
                        values,
                        handleSubmit,
                    } = formRenderProps;
                    const classes = classNames(rootClassName, className);
                    const selectSportValue = values && values.sport ? allSports.filter((st) => st.value == values.sport) : false;

                    const submitEnable = values && values.sport && values?.location?.search && values?.location?.selectedPlace?.bounds;
                    const submitEnableSchool = values && values.schoolName && values.schoolName.trim();

                    return (
                        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="SearchPage">
                            {type ? null : <div className={css.coverHeadingName}>
                                <h1> <FormattedMessage id="LandingPage.takingLessons" /><span><FormattedMessage id="LandingPage.today" /></span></h1>
                                <h4><FormattedMessage id="LandingPage.sportNearYou" /></h4>
                            </div>
                            }
                            <div className={css.coverInputBox}>
                                {/* {searchType == SEARCH_TYPE_SCHOOL ? <> */}
                                {true ? <>
                                    <FieldTextInput
                                        id={`schoolName`}
                                        name="schoolName"
                                        className={css.inputBox}
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'LandingPage.schoolNamePlaceholder' })}

                                    />
                                </>
                                    : <>
                                        <div>
                                            <Select
                                                className={css.sportsSelect}
                                                options={allSports}
                                                components={{ Option: IconOption, SingleValue: IconSingleValue, }}
                                                onChange={(e) => {
                                                    form.change("sport", e.value)
                                                }}
                                                placeholder={intl.formatMessage({ id: 'LandingPage.selectYourSportPlaceholder' })}
                                                isSearchable={false}
                                                name={`sports`}
                                                value={(selectSportValue && selectSportValue.length && selectSportValue[0]) || ""}
                                            />
                                            {/* <FieldSelect
                                        id={`sport`}
                                        className={css.inputBox}
                                        name={`sport`}
                                    >
                                        {allSports.map((st) => <option key={st.key} value={st.key}>{st.label}</option>)}
                                    </FieldSelect>
                                    <span>
                                     <SportsIcon  key={"wedwed"} /> 
                                    </span> */}
                                        </div>
                                        <div className={css.locationWrapper}>
                                            <FieldLocationAutocompleteInput
                                                className={css.inputBox}
                                                rootClassName={css.locationAddress}
                                                inputClassName={css.locationAutocompleteInput}
                                                iconClassName={css.locationAutocompleteInputIcon}
                                                predictionsClassName={css.predictionsRoot}
                                                validClassName={css.validLocation}
                                                autoFocus={autoFocus}
                                                name="location"
                                                // label={intl.formatMessage({ id: 'ProfileSettingsForm.address' })}
                                                placeholder={intl.formatMessage({ id: 'LandingPage.selectYourCityPlaceholder' })}
                                                useDefaultPredictions={false}
                                                format={identity}
                                                valueFromForm={values.location}
                                            />
                                        </div>
                                    </>
                                }
                                {/* searchType == SEARCH_TYPE_SCHOOL ? !(submitEnableSchool && submitEnableSchool.length>2) : !submitEnable */}
                                <Button type="submit" disabled={!(submitEnableSchool && submitEnableSchool.length>2)}>
                                    <FormattedMessage id="LandingPage.button" />
                                </Button>
                            </div>
                            {/* <div className={css.searchbarAssembler} onClick={(e) => {
                                    e.preventDefault();
                                    searchType == SEARCH_TYPE_SCHOOL ? setSearchType(SEARCH_TYPE_ADDRESS) : setSearchType(SEARCH_TYPE_SCHOOL);
                                }}>
                                    <FormattedMessage id={searchType == SEARCH_TYPE_SCHOOL ? "LandingPage.sportAndLocation" : "LandingPage.backToSearchBar"} />
                                </div> */}
                        </Form>
                    );
                }}
            />
        );
    }
}

SearchSportsFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    desktopInputRoot: null,
    isMobile: false,
};

SearchSportsFormComponent.propTypes = {
    rootClassName: string,
    className: string,

    isMobile: bool,
    appConfig: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const SearchSportsForm = injectIntl(SearchSportsFormComponent);

export default SearchSportsForm;
